angular.module('cpformplastApp')
  .controller('ItemEfficiencyModal', function ($scope, Util, appConfig, $http, ItemEfficiencyReport, Notification, $modalInstance, item, subjobSchedule) {

    $scope.item = item;
    $scope.subjobSchedule = subjobSchedule;

    $scope.createEfficiencyReport = function(sheetsPerHour) {
      const url = appConfig.api.url + "/api/items/" + $scope.item.id + "/efficiencyReports";
      const body = {
        sheetsPerHour: sheetsPerHour,
        subjobScheduleId: $scope.subjobSchedule.id,
        machineId: $scope.subjobSchedule.machineId
      }
      $http.post(url, body)
        .then(res => { subjobSchedule.efficiencyReports.push(new ItemEfficiencyReport(res.data)); })
        .catch(error => { Notification.error('Un problème est survenu'); })
    };

    $scope.getElegantReportDate = function(report) {
      return Util.getDateTimeString(new Date(report.createdAt));
    };

  });
